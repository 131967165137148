import React, { useContext } from "react"
import { StyledHeader } from "../Components/styled-components/Header.style"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../store/StoreProvider"
import ToolCard from "../Components/ToolCard"

const ToolSection = () => {
  const data = useStaticQuery(graphql`
    query toolsSectionQuery {
      allDatoCmsContent {
        nodes {
          toolsHeader
          contentLang
        }
      }
      allDatoCmsTool {
        nodes {
          toolId
          toolName
          toolLogo {
            url
          }
        }
      }
    }
  `)
  const { language } = useContext(StoreContext)

  const { toolsHeader } = data.allDatoCmsContent.nodes.find(
    el =>
      String(el.contentLang).toLowerCase() === String(language).toLowerCase()
  )
  const content = data.allDatoCmsTool.nodes

  const tools = content.map(el => {
    return (
      <ToolCard key={el.toolId} logoSrc={el.toolLogo.url} name={el.toolName} />
    )
  })

  return (
    <section className="tool-section">
      <StyledHeader>{toolsHeader}</StyledHeader>
      <article className="tool-section-container">{tools}</article>
    </section>
  )
}

export default ToolSection
