import React, { useContext } from "react"
import {
  StyledHeader,
  StyledImage,
} from "../Components/styled-components/index.styledComponents"
import { useStaticQuery, graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import { StoreContext } from "../store/StoreProvider"

const AboutMeSection = ({ animation }) => {
  const { language } = useContext(StoreContext)

  const {
    allDatoCmsContent: { nodes: data },
  } = useStaticQuery(graphql`
    query aboutMeSectionQuery {
      allDatoCmsContent {
        nodes {
          aboutmeParagraphes {
            value
          }
          aboutmeHeader
          contentLang
          aboutmeImage {
            url
            format
          }
        }
      }
    }
  `)

  let content = data.find(
    el =>
      String(el.contentLang).toLowerCase() === String(language).toLowerCase()
  )

  return (
    <section className="about-me-section">
      <StyledHeader alignCenter className={animation}>
        {content.aboutmeHeader}
      </StyledHeader>
      <article className="about-me-section__container">
        <StyledImage src={content.aboutmeImage?.url} className={animation} />
        {/* <StyledParagraph className="about-me-section__paragraph" aboutMeSection> */}
        <figure>
          <StructuredText data={content.aboutmeParagraphes} />
        </figure>
        {/* </StyledParagraph> */}
      </article>
    </section>
  )
}

export default AboutMeSection
