import React, { useContext } from "react"
import Activity from "../Components/Activity"
import { StyledHeader } from "../Components/styled-components/index.styledComponents"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../store/StoreProvider"

const FreeTimeSection = () => {
  const { language } = useContext(StoreContext)

  const data = useStaticQuery(graphql`
    query freeTimeSectionQuery {
      allDatoCmsContent {
        nodes {
          myfreetime
          contentLang
        }
      }
      allDatoCmsFreetime {
        nodes {
          freetimeId
          freetimeTitlePl
          freetimeDescriptionPl
          freetimeTitleEn
          freetimeDescriptionEn
          freetimeImage {
            url
          }
        }
      }
    }
  `)
  let lang = String(language).toLowerCase()

  const header = data.allDatoCmsContent.nodes.find(
    el => String(el.contentLang).toLowerCase() === lang
  )
  const activities = data.allDatoCmsFreetime.nodes
  const myActivities = activities.map(activity => (
    <Activity
      key={activity.freetimeId}
      img={activity.freetimeImage.url}
      title={
        lang === "pl" ? activity.freetimeTitlePl : activity.freetimeTitleEn
      }
      paragraph={
        lang === "pl"
          ? activity.freetimeDescriptionPl
          : activity.freetimeDescriptionEn
      }
    />
  ))
  return (
    <section className="freetime-section">
      <article className="freetime-section__container">
        <StyledHeader alignCenter>{header.myfreetime}</StyledHeader>
        <section className="freetime-section__activities-container">
          {myActivities}
        </section>
      </article>
    </section>
  )
}

export default FreeTimeSection
