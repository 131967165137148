import React, { useContext, useEffect } from "react"
import News from "../Components/News"
import Button from "../Components/Button"
import { StyledHeader } from "../Components/styled-components/index.styledComponents"
import { StoreContext } from "../store/StoreProvider"
import { VanillaTilt } from "../animations/tilt-3d/tilt"
import { useStaticQuery, graphql } from "gatsby"

const BlogSection = ({ data }) => {
  const { language } = useContext(StoreContext)

  const articles = data.allDatoCmsArticle.edges.filter(
    article =>
      article.node.language?.toLowerCase() === String(language).toLowerCase()
  )

  const content = useStaticQuery(graphql`
    query blogSectionQuery {
      allDatoCmsContent {
        nodes {
          contentLang
          blogHeader
          checkallposts
          showpostbutton
          loadmorepostsbutton
        }
      }
    }
  `).allDatoCmsContent.nodes.find(
    el =>
      String(el.contentLang).toLowerCase() === String(language).toLowerCase()
  )

  const { blogHeader, showpostbutton, loadmorepostsbutton, checkallposts } =
    content
  const { newsLimit, setNewsLimit } = useContext(StoreContext)

  const setNewsLimitHandler = e => {
    e.preventDefault()
    if (data.allDatoCmsArticle.edges.length > newsLimit) {
      setNewsLimit(newsLimit + 3)
    } else {
      location.href = "/blog"
    }
  }

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".news-card"), {
      max: 5,
      speed: 400,
    })
  }, [newsLimit])

  const news = articles
    .map(article => (
      <News
        className="news-card"
        key={article.node.title}
        showPostButton={showpostbutton}
        postTitle={article.node.title}
        postDescription={article.node.description}
        thumbnail={article.node.thumbnail}
        slug={article.node.slug}
        link={`/blog/${article.node.slug}/`}
      />
    ))
    .slice(0, newsLimit)

  return (
    <section className="blog-section">
      <StyledHeader alignCenter>{blogHeader}</StyledHeader>
      <article className="blog-section__container">
        <section className="blog-section__news-container"> {news} </section>
        <Button
          className="button-hover"
          loadMore
          buttonText={
            data.allDatoCmsArticle.edges.length > newsLimit
              ? loadmorepostsbutton
              : checkallposts
          }
          click={setNewsLimitHandler}
        />
      </article>
    </section>
  )
}
export default BlogSection
