import gsap from "gsap"
import CSSRulePlugin from "gsap/CSSRulePlugin"

export class ProjectSwitchAnimation {
  constructor() {
    gsap.registerPlugin(CSSRulePlugin)
    this.background = CSSRulePlugin.getRule(".project-section:before")
    this.desktopImage = document.querySelector(".project-desktop-image")
    this.mobileImage = document.querySelector(".project-mobile-image")
    this.description = document.querySelector(".project-description-container")

    this.exitTime = 0.3
    this.showTime = 0.3
  }

  async hideProject() {
    const switchAnimation = gsap.timeline({
      defaults: {
        ease: "expo",
      },
    })

    gsap.set(this.description, { opacity: 0 })

    await switchAnimation
      .add("start")
      .to(
        this.background,
        {
          duration: this.exitTime * 2,
          cssRule: { backgroundColor: "#ffffff" },
        },
        "start"
      )
      .to(
        this.desktopImage,
        { duration: this.exitTime, opacity: 0, x: "-50%" },
        "start"
      )
      .to(
        this.mobileImage,
        { duration: this.exitTime, opacity: 0, x: "-50%" },
        "start"
      )
  }

  async showProject() {
    const switchAnimation = gsap.timeline({
      defaults: {
        ease: "expo",
      },
    })

    await switchAnimation
      .add("half")
      .to(
        this.background,
        {
          duration: this.showTime,
          cssRule: { backgroundColor: "transparent" },
        },
        "half"
      )
      .fromTo(
        this.desktopImage,
        { opacity: 1, x: "50%" },
        { duration: this.showTime, opacity: 1, x: "0" },
        "half"
      )
      .fromTo(
        this.mobileImage,
        { opacity: 1, x: "50%" },
        { duration: this.showTime, opacity: 1, x: "0" },
        "half"
      )
      .to(this.description, { duration: this.showTime, opacity: 1 }, "half")
  }
}
