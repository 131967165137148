import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useContext } from "react"
import { StoreContext } from "../store/StoreProvider"
import { StyledHeader } from "../Components/styled-components/Header.style"
import desktopFrame from "../assets/notebookframe.png"
import mobileFrame from "../assets/apple-iphone-14-max-2022-medium.png"
import { StyledParagraph } from "../Components/styled-components/Paragraph.style"
import Button from "../Components/Button"
import { StyledSubheader } from "../Components/styled-components/Subheader.style"
// TODO: add popup
// import ProjectPopup from "../Components/ProjectPopup"
import { ProjectSwitchAnimation } from "../animations/projectSwitchAnimation/ProjectSwitchAnimation.js"
import noSignalImageDesktop from "../assets/images/stop-desktop.jpg"
import noSignalImageMobile from "../assets/images/stop-mobile.jpg"

const ProjectsSection = () => {
  const data = useStaticQuery(graphql`
    query projectSectionQuery {
      allDatoCmsContent {
        nodes {
          projectHeader
          contentLang
        }
      }
      allDatoCmsProject(sort: { order: ASC, fields: projectId }) {
        nodes {
          projectId
          projectSlug
          projectRole
          projectTitle
          projectDescriptionPl
          projectDescriptionEn
          projectMobileImg {
            url
          }
          projectDesktopImg {
            url
          }
        }
      }
    }
  `)
  const { language } = useContext(StoreContext)
  const [activeProject, setActiveProject] = useState({})
  // TODO: add popup
  // const [isPopupOpen, setIsPopupOpen] = useState(false)

  const { projectHeader } = data.allDatoCmsContent.nodes.find(
    el =>
      String(el.contentLang).toLowerCase() === String(language).toLowerCase()
  )
  const content = data.allDatoCmsProject.nodes

  const handleSelectProject = async index => {
    const projectAnimation = new ProjectSwitchAnimation()
    await projectAnimation.hideProject()
    setActiveProject(content[index])
    await projectAnimation.showProject()
  }
  // TODO: add popup
  // const handleOpenPopup = e => {
  //   e.preventDefault()
  //   setIsPopupOpen(true)
  // }

  // const handleClosePopup = e => {
  //   e.preventDefault()
  //   setIsPopupOpen(false)
  // }

  useEffect(() => {
    setActiveProject(content[0])
  }, [])

  const description =
    String(language).toLowerCase() === "pl"
      ? activeProject.projectDescriptionPl
      : activeProject.projectDescriptionEn

  const projectList = content.map((el, index) => {
    return (
      <figure key={el.projectId} className="project-list-element">
        <StyledHeader
          onClick={() => handleSelectProject(index)}
          className={
            el.projectTitle === activeProject.projectTitle
              ? "active-project"
              : ""
          }
        >
          {el.projectTitle}
        </StyledHeader>
        <StyledSubheader subheaderSmall>{el.projectRole}</StyledSubheader>
      </figure>
    )
  })

  return (
    <>
      <StyledHeader alignCenter>{projectHeader}</StyledHeader>
      <section
        className="project-section"
        style={{
          backgroundImage: `url(${
            activeProject.projectDesktopImg?.url || noSignalImageDesktop
          })`,
        }}
      >
        <article className="project-section-container">
          <section className="project-content">
            <figure className="project-desktop-image-container">
              <img className="project-desktop-image-frame" src={desktopFrame} />
              <div className="project-desktop-image-screen">
                <img
                  className="project-desktop-image"
                  src={
                    activeProject.projectDesktopImg?.url || noSignalImageDesktop
                  }
                />
              </div>
            </figure>
            <figure className="project-mobile-image-container">
              <img className="project-mobile-image-frame" src={mobileFrame} />
              <img
                className="project-mobile-image"
                src={activeProject.projectMobileImg?.url || noSignalImageMobile}
              />
            </figure>
            <figure className="project-description-container">
              <StyledParagraph className="project-description">
                {description}
              </StyledParagraph>
              <section className="project-buttons-container">
                {/*
                 TODO: add popup
                 <Button
                  buttonText={language === "pl" ? "Więcej" : "More"}
                  click={handleOpenPopup}
                /> */}
                {activeProject.projectSlug && (
                  <Button
                    extLink={activeProject.projectSlug}
                    buttonText={
                      language === "pl" ? "Odwiedź stronę" : "Visit site"
                    }
                  />
                )}
              </section>
            </figure>
          </section>
          <section className="project-list">{projectList}</section>
        </article>
        {/* 
         TODO: add popup 
        {isPopupOpen && <ProjectPopup close={handleClosePopup} />} */}
      </section>
    </>
  )
}

export default ProjectsSection
