import React from "react"
import { StyledContactIcons } from "./styled-components/ContactIcons.style"
import { useStaticQuery, graphql } from "gatsby"

export const ContactIcons = ({ column, fixedFloater }) => {
  const icons = useStaticQuery(graphql`
    query contactIconsQuery {
      allDatoCmsContact {
        nodes {
          contactId
          contactLink
          contactIcon {
            url
          }
        }
      }
    }
  `).allDatoCmsContact.nodes

  const contactPicture = icons.map(el => (
    <a key={el.contactId} href={el.contactLink}>
      <img className="social-icon" src={el.contactIcon.url} />
    </a>
  ))
  return (
    <StyledContactIcons
      className={fixedFloater ? "contact-floater__contact-icons" : null}
      column={column}
      fixedFloater={fixedFloater}
    >
      {contactPicture}
    </StyledContactIcons>
  )
}

export default ContactIcons
