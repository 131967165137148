import React from "react"
import { StyledToolCard } from "./styled-components/ToolCard.style"
import { StyledParagraph } from "./styled-components/Paragraph.style"

const ToolCard = ({ logoSrc, name }) => {
  return (
    <StyledToolCard>
      <div className="tool-card-image-container">
        <img className="tool-card-image" src={logoSrc} />
      </div>
      <StyledParagraph className="tool-card-name">{name}</StyledParagraph>
    </StyledToolCard>
  )
}

export default ToolCard
