import React, { useContext } from "react"

import {
  StyledHeader,
  StyledSubheader,
  StyledImage,
} from "../Components/styled-components/index.styledComponents"
import { StoreContext } from "../store/StoreProvider"

import { useStaticQuery, graphql } from "gatsby"
import { StyledHeaderHuge } from "../Components/styled-components/Header.style"

const HelloSection = () => {
  const { language } = useContext(StoreContext)
  const {
    allDatoCmsContent: { nodes: data },
  } = useStaticQuery(graphql`
    query helloSectionQuery {
      allDatoCmsContent {
        nodes {
          helloHeader
          helloHeaderAccent
          helloHeaderHuge
          helloSubheader
          helloSubheaderLight
          helloSubheaderLightSmall
          helloSubheaderSmall
          contentLang
          helloImg {
            url
            format
          }
        }
      }
    }
  `)
  const { isMenuOpen } = useContext(StoreContext)
  let content = data.find(
    node =>
      String(node.contentLang).toLowerCase() === String(language).toLowerCase()
  )
  return (
    <section className="hello-section">
      <article className="hello-section__wrapper helloHeader ">
        <section className="hello-section__header">
          <StyledSubheader
            className=" outer"
            helloSection
            isMenuOpen={isMenuOpen}
          >
            <span className="accent-color">{content.helloHeaderAccent}</span>{" "}
            {content.helloHeader}
          </StyledSubheader>
          <StyledHeaderHuge
            className=" outer"
            helloSection
            isMenuOpen={isMenuOpen}
          >
            {content.helloHeaderHuge}
          </StyledHeaderHuge>
        </section>

        <StyledImage
          className="hello-section__image helloImage"
          src={content.helloImg.url}
          helloSection
        />

        <section className="hello-section__subheader">
          <figure className="hello-section__subheader-items-container">
            <StyledHeader
              className="outer"
              helloSection
              isMenuOpen={isMenuOpen}
            >
              {content.helloSubheader}
            </StyledHeader>
            <StyledSubheader subheaderSmall className=" outer">
              {content.helloSubheaderSmall}
            </StyledSubheader>
          </figure>

          <figure className="hello-section__subheader-items-container">
            <StyledHeader
              className="outer"
              helloSection
              lightColor
              isMenuOpen={isMenuOpen}
            >
              {content.helloSubheaderLight}
            </StyledHeader>
            <StyledSubheader subheaderSmall className=" outer">
              {content.helloSubheaderLightSmall}
            </StyledSubheader>
          </figure>
        </section>
      </article>
    </section>
  )
}

export default HelloSection
