import React, { useContext, useEffect } from "react"
import {
  StyledHeader,
  StyledImage,
  StyledSubheader,
} from "../Components/styled-components/index.styledComponents"
import ContactIcons from "../Components/ContactIcons"
import { socialIconHoverAnimation } from "../animations/hover/socialIconHover"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../store/StoreProvider"

const ContactSection = () => {
  const { language } = useContext(StoreContext)

  const {
    allDatoCmsContent: { nodes: data },
  } = useStaticQuery(graphql`
    query contactSectionQuery($language: String) {
      allDatoCmsContent(filter: { contentLang: { eq: $language } }) {
        nodes {
          contentLang
          contactSubheader
          contactHeader
          contactImage {
            url
            format
          }
        }
      }
    }
  `)

  const content = data.find(
    el =>
      String(el.contentLang).toLowerCase() === String(language).toLowerCase()
  )
  const { contactHeader, contactSubheader, contactImage } = content

  useEffect(() => {
    socialIconHoverAnimation()
  }, [])

  return (
    <section className="contact-section">
      <StyledImage
        className="contact-section__image"
        contact
        src={contactImage.url}
      />
      <article className="contact-section__container">
        <StyledSubheader className="contact-section__subheader">
          {contactSubheader}
        </StyledSubheader>
        <ContactIcons />
      </article>
      <StyledHeader className="contact-section__header">
        {contactHeader}
      </StyledHeader>
    </section>
  )
}

export default ContactSection
